import { createSelector } from "reselect"

export const selectSession = state => state.session
export const selectDivisionConfig = state => state.session?.divisionConfig
const isFeatureEnabled = (divisionConfig, feature) => divisionConfig?.find(item => item.name === feature)?.enabled

export const selectIsDocumentationEnabledFromSettings = createSelector([selectDivisionConfig], divisionConfig =>
  isFeatureEnabled(divisionConfig, "DOCUMENTATION"),
)

export const selectIsNinjaItamEnabledFromSettings = createSelector([selectDivisionConfig], divisionConfig =>
  isFeatureEnabled(divisionConfig, "NINJA_ITAM"),
)
